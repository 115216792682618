<template>
  <van-dialog
      v-model:show="show"
      class="brush-box"
      :show-cancel-button="false"
      :show-confirm-button="false"
      @close="btnShow = true"
  >
    <div class="brush-container">
      <van-nav-bar @clickLeft="close" @clickRight="submit">
        <template #title>
          <div style="display: flex;align-items: center;">
            <van-slider
                v-model="width"
                bar-height="2px"
                button-size="16px"
                :step="1"
                :min="5"
                :max="15"
                style="width: 100px;"
                @change="brushWidthChange"
            />
            <span style="margin-left: 15px;">{{ width }}</span>
          </div>
        </template>
        <template #left>
          <van-icon name="cross" size="22" />
        </template>
        <template #right>
          <van-icon name="success" size="22" />
        </template>
      </van-nav-bar>

      <div class="brush-content">
        <canvas id="brush" />
      </div>
    </div>
  </van-dialog>
</template>

<script>
import { Button, Icon, NavBar, Dialog, Slider } from 'vant';
import { watch, ref, nextTick } from "vue";
import { fabric } from "fabric";

export default {
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
    [Dialog.name]: Dialog,
    [Slider.name]: Slider,
  },
  props: {
    brushShow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'change'],
  setup(props, { emit }) {
    watch(
        () => props.brushShow,
        (val) => {
          show.value = val;
          if (val) {
            width.value = 10;
            if (brushCanvas) {
              // 清除原来的图案
              brushCanvas.clear();
            } else {
              initCanvas();
            }
          }
        }
    );

    let brushCanvas = null;
    const show = ref(false);
    const width = ref(10);

    const initCanvas = () => {
      nextTick(() => {
        const el = document.getElementsByClassName('brush-content')[0];
        brushCanvas = new fabric.Canvas('brush', { selection: false });
        brushCanvas.setDimensions({
          width: el.offsetWidth,
          height: el.offsetHeight,
        });
        brushCanvas.isDrawingMode = true;
        const pencilBrush = new fabric.PencilBrush(brushCanvas);
        pencilBrush.width = width.value;
        brushCanvas.freeDrawingBrush = pencilBrush;
      });
    };

    const brushWidthChange = (val) => {
      const pencilBrush = new fabric.PencilBrush(brushCanvas);
      pencilBrush.width = val;
      brushCanvas.freeDrawingBrush = pencilBrush;
    };

    const close = () => {
      emit('close');
    };

    const submit = () => {
      emit('change', brushCanvas.toCanvasElement())
    }

    return {
      show,
      width,
      close,
      submit,
      brushWidthChange,
    }
  }
}
</script>

<style lang="less">
.brush-box {
  height: 90vh;
  top: 50% !important;
  .van-dialog__content {
    height: 100%;
  }
  .brush-container {
    height: 100%;
    width: 100%;
    .brush-content {
      height: calc(100% - 46px);
    }
  }
}
</style>
