<template>
  <div class="container">
    <div class="good-box" ref="goodRef">
      <div :style="`height: ${showImgWidth}px; width: ${showImgWidth}px;`">
        <canvas id="minimap"></canvas>
      </div>
    </div>

    <van-row justify="space-between" class="op-btn">
      <van-col span="12">
        <template v-if="canUndo">
          <van-icon name="revoke" size="30" color="#fff" @click="undoOperate" />
        </template>
        <template v-else>
          <van-icon name="revoke" size="30" color="darkgray" />
        </template>
        <template v-if="canRedo">
          <van-icon name="revoke" size="30" color="#fff" style="transform: scaleX(-1);margin-left: 1rem"
            @click="redoOperate" />
        </template>
        <template v-else>
          <van-icon name="revoke" size="30" color="darkgray" style="transform: scaleX(-1);margin-left: 1rem" />
        </template>
      </van-col>
      <van-col span="6">
        <van-button class="save-btn" round size="small" block @click="saveFile">
          保存
        </van-button>
      </van-col>
    </van-row>

    <div v-show="btnShow" class="content" ref="canvasRef">
      <canvas id="canvas" />
    </div>

    <van-grid v-if="btnShow" :gutter="10" :column-num="4" class="btn-container">
      <van-grid-item class="btn-box" @click="photoBtnClick">
        <template #icon>
          <van-image width="100%" :src="tietuPng" />
        </template>
        <template #text>
          <div class="btn-txt">图案</div>
        </template>
      </van-grid-item>
      <van-grid-item class="btn-box" @click="customerTextClick">
        <template #icon>
          <van-image width="100%" :src="shuruPng" />
        </template>
        <template #text>
          <div class="btn-txt">文字</div>
        </template>
      </van-grid-item>
      <van-grid-item class="btn-box" @click="brushClick">
        <template #icon>
          <van-image width="100%" :src="shouxiePng" />
        </template>
        <template #text>
          <div class="btn-txt">手写</div>
        </template>
      </van-grid-item>
      <van-grid-item class="btn-box">
        <van-uploader :after-read="uploadImg" accept="image/*">
          <van-image width="100%" :src="zidinyiPng" />
          <div class="btn-txt">图片</div>
        </van-uploader>
      </van-grid-item>
    </van-grid>

    <!-- 贴纸选择 -->
    <photo :photo-show="photoShow" :title="photoTitle" @change="submitPhoto" @close="closePhoto" />

    <!-- 自定义文字 -->
    <customer-text :customer-text-show="customerTextShow" @change="confirmCustomerText" @close="closeCustomerText" />

    <!-- 画笔 -->
    <brush :brush-show="brushShow" @close="closeBrush" @change="brushSubmit" />

  </div>
</template>

<script>
import {
  Button,
  Col,
  Grid,
  GridItem,
  Icon,
  Image as VanImage,
  Row,
  showFailToast,
  showLoadingToast,
  Uploader
} from 'vant';
import { nextTick, onMounted, ref } from 'vue';
import { request } from '@/utils/request';
import { getUrlParam } from '@/utils/auth';
import { amountFormat } from '@/utils/util';
import { useRoute } from "vue-router";
import { fabric } from 'fabric';
import '@/utils/fabric/history';
import { deleteControl, rotationControl } from '@/utils/fabric/customRender';
import { createObject, limitObjectArea, limitObjectIntersect } from '@/utils/fabric/utils';

import Brush from './components/Brush.vue';
import Photo from './components/Photo.vue';
import CustomerText from './components/CustomerText.vue';
import coinsBgPng from '@/assets/coins_bg2.png';
import tietuPng from '@/assets/tietu.png';
import shuruPng from '@/assets/shuru.png';
import shouxiePng from '@/assets/shouxie.png';
import zidinyiPng from '@/assets/zidinyi.png';
import router from "@/router";

export default {
  components: {
    Brush,
    Photo,
    CustomerText,
    [Button.name]: Button,
    [Col.name]: Col,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Icon.name]: Icon,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Uploader.name]: Uploader,
  },
  setup() {
    // 接受请求参数
    const route = useRoute();
    const brands = ref([]);
    const finished = ref(false);
    const contactNumber = ref('');
    const { params: { productId } } = route;
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');
    const typeLogo = getUrlParam('typeLogo');
    // 展示页面上图片尺寸
    const showImgWidth = 270;

    // 设计图纸尺寸
    const draw_width = 400;

    // 限制最后添加10个元素
    const maxCoinsNum = 10;

    // 画布
    const canvasRef = ref();
    const goodRef = ref();
    let fabricCanvas = null;
    let minimap = null;
    // 是否可以撤销
    const canUndo = ref(false);
    // 是否可以重做
    const canRedo = ref(false);
    // 拖拽的外部图片url
    const dropImgUrl = ref('');

    const canvasHeight = ref(0);

    // 贴纸选择
    const photoShow = ref(false);
    const photoTitle = ref('');

    // 自定义文字
    const customerTextShow = ref(false);

    // 画笔按钮
    const brushShow = ref(false);

    // 底部按钮显示
    const btnShow = ref(true);

    onMounted(async () => {
      // 屏幕宽度除以图纸比例尺寸得出页面展示绘图区高度
      await nextTick();
      // 初始化画板
      initCanvas();
      drawBg();

      initMinimap();

      // 监听事件
      addCanvasEvent();
      // 自定义删除图标
      deleteControl(fabricCanvas);
      // 自定义旋转图标
      rotationControl();
    });

    const initCanvas = () => {
      // 初始化画布
      fabricCanvas = new fabric.Canvas('canvas');

      // 隐藏所有对象的控制点，只保留顶部旋转点
      ['tl', 'br', 'ml', 'mb', 'mr', 'mt', 'mtr'].forEach(e => {
        fabric.Object.prototype.controls[e] = new fabric.Control({
          visible: false
        });
      });
    };

    const initMinimap = () => {
      // 初始化预览图
      minimap = new fabric.Canvas('minimap', {
        containerClass: 'minimap',
        selection: false,
        fill: 'transparent',
        width: showImgWidth,
        height: showImgWidth,
      });
      // 渲染背景图片
      fabric.Image.fromURL(coinsBgPng, function (img) {
        // 图片缩放
        const scale = fabric.util.findScaleToFit(img, minimap).toFixed(1);
        img.scale(scale);
        minimap.setBackgroundImage(img);
        minimap.renderAll();
      });
    }

    const updateMiniMap = () => {
      minimap.clear();
        var designSize = { width: 200, height: 200 };
        const minimapRatio = fabric.util.findScaleToFit(fabricCanvas, designSize).toFixed(2);
        const canvasElement = fabricCanvas.toCanvasElement(minimapRatio);
        const backgroundImage = new fabric.Image(canvasElement, {
            left: 35,
            top: 35,
            width: canvasElement.width,
            height: canvasElement.height,
            selectable: false,
        });

      fabric.Image.fromURL(coinsBgPng, function (img) {
        const scale = fabric.util.findScaleToFit(img, minimap);
        img.scale(scale).set({ selectable: false });
        minimap.add(img);
        minimap.add(backgroundImage);
        minimap.renderAll();
      });
    }

    // 绘制背景图片
    const drawBg = () => {
      setTimeout(() => {
        canvasHeight.value = canvasRef.value.offsetHeight < 200 ? 200 : canvasRef.value.offsetHeight;
        canvasHeight.value = canvasRef.value.offsetWidth < canvasHeight.value ? canvasRef.value.offsetWidth : canvasHeight.value;

        fabricCanvas.clipPath = new fabric.Circle({
          radius: canvasHeight.value / 2,
          originX: 'center',
          originY: 'center',
          left: canvasHeight.value / 2,
          top: canvasHeight.value / 2,
          selectable: false,
          fill: 'transparent',
        });
        // 设置canvas的宽高
        fabricCanvas.setWidth(canvasHeight.value);
        fabricCanvas.setHeight(canvasHeight.value);
        // 保存绘制背景图片的操作历史
        fabricCanvas._historySaveAction();
      }, 200);
    }

    // canvas添加事件（对象left和top的坐标是左上角）
    const addCanvasEvent = () => {
      canvasObjectMoving();
      canvasObjectModified();
    }

    // 监控 fabricCanvas 的 moving 方法
    const canvasObjectMoving = () => {
      fabricCanvas.on('object:moving', function (options) {
        fabricCanvas.onHistory();
        limitObjectArea(options)
        limitObjectIntersect(options, fabricCanvas);
      })
    };

    const canvasObjectModified = () => {
      fabricCanvas.on('object:modified', function() {
        updateMiniMap();
      });
      fabricCanvas.on('object:removed', function () {
        updateMiniMap();
      });
    }

    // 撤销
    const undoOperate = () => {
      fabricCanvas.undo();
      canUndo.value = fabricCanvas.canUndo();
      canRedo.value = fabricCanvas.canRedo();
      updateMiniMap();
    };

    // 重做
    const redoOperate = () => {
      fabricCanvas.redo();
      canUndo.value = fabricCanvas.canUndo();
      canRedo.value = fabricCanvas.canRedo();
      updateMiniMap();
    };

    /**
     * 绘制图片
     * @param pointerVpt 图片位置
     * @param isGray 是否为自定义图片
     */
    const drawImg = (pointerVpt, isGray) => {
      fabric.Image.fromURL(dropImgUrl.value, function(obj) {
        // 设置图片大小
        obj.scaleToWidth(70)
        obj.scaleToHeight(70)
        obj.set({
          top: fabricCanvas.height / 2,
          left: fabricCanvas.width / 2,
          textAlign: 'center',
          originX: 'center',
          originY: 'center',
          borderColor: '#6a7bff',
          cornerColor: '#6a7bff',
          cornerSize: 16,
          cornerStyle: 'circle',
          transparentCorners: false,
          lockScalingFlip: false,
          objectCaching: true,
          borderOpacityWhenMoving: 1,
        });
        // 是否为自定义图片
        if (isGray) {
          obj.set({is_gray: true});
        }

        createObject(obj, fabricCanvas);
        updateMiniMap();
      }, {crossOrigin: 'anonymous'});
      canUndo.value = true;
    }

    // 点击贴纸图标
    const photoBtnClick = () => {
      if (fabricCanvas.getObjects().length >= maxCoinsNum) {
        showFailToast(`最后只能添加${maxCoinsNum}个元素!`);
        return;
      }
      photoTitle.value = '贴纸';
      btnShow.value = false;
      photoShow.value = true;
    };

    // 提交贴纸数据
    const submitPhoto = (photo) => {
      btnShow.value = true;
      photoShow.value = false;

      dropImgUrl.value = photo;

      // 鼠标坐标转换成画布的坐标（未经过缩放和平移的坐标）
      const point = { x: 100, y: 100 };
      const pointerVpt = fabricCanvas.restorePointerVpt(point);
      // 绘制图片
      drawImg(pointerVpt);
    };

    // 关闭贴纸弹窗
    const closePhoto = () => {
      btnShow.value = true;
      photoShow.value = false;
    };

    // 点击自定义文字按钮
    const customerTextClick = () => {
      if (fabricCanvas.getObjects().length >= maxCoinsNum) {
        showFailToast(`最后只能添加${maxCoinsNum}个元素!`);
        return;
      }
      btnShow.value = false;
      customerTextShow.value = true;
    };

    const closeCustomerText = () => {
      btnShow.value = true;
      customerTextShow.value = false;
    };

    // 确认自定义文字
    const confirmCustomerText = (value) => {
      if (value.text) {
        generateText(value);
      }
      btnShow.value = true;
      customerTextShow.value = false;
    };

    /**
     * 生成文字
     * @param value
     */
    const generateText = (value) => {
      const textObj = new fabric.Text(value.text, {
        top: fabricCanvas.height / 2,
        left: fabricCanvas.width / 2,
        originX: 'center',
        originY: 'center',
        borderColor: '#6a7bff',
        cornerColor: '#6a7bff',
        cornerSize: 16,
        cornerStyle: 'circle',
        transparentCorners: false,
        lockScalingFlip: false,
        objectCaching: true,
        borderOpacityWhenMoving: 1,
        fontSize: 30,
        fontWeight: value.bold ? 'bold': 'normal',
        fontFamily: value.fontFamily
      });

      createObject(textObj, fabricCanvas);
      updateMiniMap();
    }

    // 点击画笔按钮
    const brushClick = () => {
      if (fabricCanvas.getObjects().length >= maxCoinsNum) {
        showFailToast(`最后只能添加${maxCoinsNum}个元素!`);
        return;
      }
      btnShow.value = false;
      brushShow.value = true;
    };

    const closeBrush = () => {
      btnShow.value = true;
      brushShow.value = false;
    };

    // 提交画笔图形
    const brushSubmit = (canvasElement) => {
      const obj = new fabric.Image(canvasElement);
      // 设置图片大小
      obj.scaleToWidth(80)
      obj.scaleToHeight(80)
      obj.set({
        top: fabricCanvas.height / 2,
        left: fabricCanvas.width / 2,
        textAlign: 'center',
        originX: 'center',
        originY: 'center',
        borderColor: '#6a7bff',
        cornerColor: '#6a7bff',
        cornerSize: 16,
        cornerStyle: 'circle',
        transparentCorners: false,
        lockScalingFlip: false,
        objectCaching: true,
        borderOpacityWhenMoving: 1,
      });
      createObject(obj, fabricCanvas);
      updateMiniMap();

      btnShow.value = true;
      brushShow.value = false;
    }

    // 保存文件
    const saveFile = () => {
      if (fabricCanvas.getObjects().length === 0) {
        return;
      }

      // 是否包含自定义图片
      const isGray = fabricCanvas.getObjects().some((obj) => 'is_gray' in obj);

      const toast = showLoadingToast({
        message: '正在保存',
        duration: 0,
        forbidClick: true,
      });
      fabricCanvas.backgroundColor = 'white';

      // 屏幕宽度除以图纸比例尺寸得出页面展示绘图区高度
      const ratio = (draw_width / canvasHeight.value).toFixed(1);

      const canvasElement = fabricCanvas.toCanvasElement(ratio);
      const combineImg  = canvasElement.toDataURL();

      // 设计图合成后的图片
      const combineBgImg = minimap.toDataURL();

      request({
        url: `/h5/createPcDraw`,
        method: 'post',
        data: { deviceNo, productId, combineImg, combineBgImg, isGray, typeLogo },
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((res) => {
        if (res.success === true && res.result) {
          router.push({ name: 'coinsBuy', params: { productId }, query: { deviceNo, drawId: res.result } })
        }
      }).catch(() => {
        showFailToast('请求出错请稍后再试');
      }).finally(() => {
        toast.close();
      });
    };

    // 自定义图片
    const uploadImg = (file) => {
      if (fabricCanvas.getObjects().length >= maxCoinsNum) {
        showFailToast(`最后只能添加${maxCoinsNum}个元素!`);
        return;
      }
      dropImgUrl.value = file.content;

      // 鼠标坐标转换成画布的坐标（未经过缩放和平移的坐标）
      const point = { x: 100, y: 100 };
      const pointerVpt = fabricCanvas.restorePointerVpt(point);
      // 绘制图片
      drawImg(pointerVpt, true);
    };

    return {
      brands,
      finished,
      deviceNo,
      contactNumber,
      showImgWidth,
      amountFormat,
      productId,
      btnShow,
      canvasRef,
      goodRef,
      canRedo,
      canUndo,
      tietuPng,
      shuruPng,
      shouxiePng,
      zidinyiPng,
      redoOperate,
      undoOperate,

      photoTitle,
      photoShow,
      photoBtnClick,
      closePhoto,
      submitPhoto,

      customerTextShow,
      confirmCustomerText,
      closeCustomerText,
      customerTextClick,

      brushShow,
      brushSubmit,
      closeBrush,
      brushClick,
      uploadImg,

      saveFile,
    };
  },
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: #fff;
  .op-btn {
    padding: 0.5rem 1rem;
    background-color: #ffc360;
    .save-btn {
      font-size: 1rem;
      color: #000;
      font-weight: 600;
      background: linear-gradient(to bottom, #e8f8f6, #faeafa);
    }
  }
  .good-box {
    width: 100%;
    height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    .van-image {
      position: relative;
    }
  }
  .content {
    background-color: #000;
    width: 100%;
    overflow: hidden;
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .btn-container {
    background-color: #F5EFE7;
    .btn-box {
      .btn-txt {
        width: 100%;
        overflow: hidden;
        color: #fff;
        font-weight: 600;
        background: #ffc360;
        padding-left: var(--van-padding-xs);
        padding-right: var(--van-padding-xs);
        border-radius: 2rem;
        line-height: 1.5rem;
        font-size: 0.9rem;
        text-align: center;
        white-space: nowrap;
      }
      :deep(.van-grid-item__content) {
        background: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>
