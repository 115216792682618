<template>
  <div class="container">
    <template v-if="loading">
      <div class="init-container">
        <van-skeleton title :row="3" />
        <van-loading vertical size="24px">加载中</van-loading>
      </div>
    </template>

    <template v-else>
      <div class="content-container">
        <!-- 商品详情 -->
        <div class="good-item">
          <!-- 商品图片 -->
          <div class="good-img">
            <van-image width="100%" :src="product.imageUrl" />
          </div>
          <!-- 商品详情 -->
          <div class="good-info">
            <div class="van-ellipsis price-box">
              <div>
                &yen;
                <span class="good-price">
                  {{ amountFormat(product.price) }}
                  <span style="font-size: 0.7rem">/{{ product.unit }}</span>
                </span>
              </div>
            </div>
            <div class="good-name" v-html="product.productName"></div>
          </div>

          <div class="good-desc">
            <van-row align="center">
              <van-col span="4">库存</van-col>
              <van-col span="20" class="desc-value">{{ product.amount }}</van-col>
            </van-row>
          </div>
        </div>
      </div>
      <!-- 购买按钮 -->
      <div class="buy-btn-box">
        <van-button round type="success" class="customize-btn" @click="startCreate">立即定制</van-button>
      </div>
      <!-- 广告 -->
<!--      <div class="ad-container">-->
<!--        <div id="goods-ad"></div>-->
<!--      </div>-->

      <!-- 轮播图 -->
      <div v-if="brands.length !== 0" class="brand-container">
        <van-swipe :autoplay="3000" lazy-render style="width: 100%;height: 100%;">
          <van-swipe-item v-for="brand in brands" :key="brand">
            <img :src="brand" width="100%" height="100%"  alt=""/>
          </van-swipe-item>
        </van-swipe>
      </div>
    </template>
  </div>
</template>

<script>
import { Button, Loading, Image as VanImage, Field, Stepper, Skeleton, Cell, Col, Row, Swipe, SwipeItem } from 'vant';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { amountFormat } from '@/utils/util';
import { request } from '@/utils/request';
import buyIcon from '@/assets/buy_icon.png';
import router from "@/router";
import { authUserInfo, getUrlParam } from '@/utils/auth';

export default {
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Loading.name]: Loading,
    [VanImage.name]: VanImage,
    [Stepper.name]: Stepper,
    [Skeleton.name]: Skeleton,
    [Row.name]: Row,
    [Col.name]: Col,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  setup() {
    // 接受请求参数
    const route = useRoute();
    const { params: { productId } } = route;
    const loading = ref(true);
    const num = ref(1);
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');
    // 运营商广告
    const brands = ref([]);

    const product = ref({
      productId: productId,
      productName: '',
      price: 10,
      quantity: 0,
      amount: 0,
      imageUrl: '',
      unit: '',
      name: '',
    });

    onMounted(() => {
      // 加载产品数据
      onLoad();
    })

    const onLoad = () => {
      loading.value = true;
      request({
        url: `/h5/device/getProByDevNoAndProId`,
        method: 'get',
        params: { deviceNo, productId },
      }).then((res) => {
        if (res.success === true) {
          product.value = { ...product.value, ...res.result };
          // 加载状态结束
          loading.value = false;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    // 开始创作
    const startCreate = () => {
      // 获取用户信息
      request({
        url: "/h5/getUserInfo",
      }).then((res) => {
        if (res.success && res.result) {
          const { name, headImgUrl } = res.result;
          // 有用户昵称和头像时可以进入创作,否则需要授权获取用户信息
          if (name && headImgUrl) {
            router.push({ name: 'coinsMaterial', params: { productId: product.value.productId }, query: { deviceNo } });
          } else {
            authUserInfo();
          }
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    return {
      num,
      product,
      loading,
      buyIcon,
      deviceNo,
      brands,
      amountFormat,
      startCreate,
    }
  }
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F7F8FA;
  .init-container {
    background-color: #F7F8FA;
    padding: 20px 0;
  }
  .ad-container {
    width: 100%;
    height: 80px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 1px solid red;
  }
  .brand-container {
    margin-bottom: 10px;
    width: 100%;
    height: 150px;
  }
  .content-container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .good-item {
      width: 100%;
      margin: 0 auto;
      font-size: 1rem;
      .good-img {
        background-color: #fff;
        overflow: hidden;
        width: 100%;
        position: relative;
        z-index: 0;
        margin-bottom: 1rem;
      }
      .good-info {
        background-color: #fff;
        overflow: hidden;
        margin-bottom: 1rem;
        padding: 1rem ;
      }
      .good-name {
        color: #000;
        font-size: 1.1rem;
        font-weight: 600;
      }
      .price-box {
        color: #F45639;
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1rem;
        .good-price {
          font-size: 1.8rem;
        }
      }
      .good-desc {
        background-color: #fff;
        overflow: hidden;
        margin-bottom: 1rem;
        padding: 1rem ;
        .desc-value {
          color: darkgray;
        }
      }
      .good-buy-num {
        padding: 0;
        margin-top: 1rem;
        :deep(.van-stepper--round .van-stepper__plus) {
          color: #fff;
          background-color: #5755B3;
        }
        :deep(.van-stepper--round .van-stepper__minus) {
          border: none;
          background-color: #5755B3;
          color: #fff;
        }
        :deep(.van-stepper__input) {
          font-size: 1.3rem;
          font-weight: 600;
        }
      }
    }
  }
  .buy-btn-box {
    background-color: #fff;
    padding: 0.7rem;
    .van-button {
      width: 100%;
      font-size: 1.2rem;
    }

    .not-customize-btn {
      color: dimgray;
      border: 1px solid darkgray;
    }
    .customize-btn {
      color: #fff;
      font-weight: 600;
      background: #ffc360;
      border: none;
    }
  }
}
</style>
