<template>
  <div class="pay">

  </div>
</template>

<script>
import { authBase, getUrlParam } from '@/utils/auth';
import { getLocalToken } from '@/utils/token';
import { request } from '@/utils/request';
import { onMounted, ref,inject} from 'vue';
import { showFailToast } from 'vant';



export default {
  setup() {
    let payJson = '';
    let deviceNo = getUrlParam('deviceNo');
    let count = getUrlParam('count')
    let name = getUrlParam('name')
    let timeStamp = getUrlParam('timeStamp')
    // 自定义绘图金币ID
    let drawId = getUrlParam('drawId')

    console.log(inject('globalEnv'),"全局环境");

    onMounted(() => {


      if (!deviceNo || !count || !name || !timeStamp) {
        showFailToast('页面数据有误, 请重新下单')
        return
      }
      console.log(deviceNo, name, count,timeStamp);

      const timeRes = Math.floor(new Date().getTime()/1000)-Number(timeStamp);
      if (timeRes < 0) {
        return showFailToast('支付时间格式不正确，请重新下单支付!')
      }
      if(timeRes>=60){
        return showFailToast('支付时间超60s，请重新下单支付!')

      }

      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf('micromessenger') > -1) {
        // wxpay
        auth()
      } else if (ua.indexOf('alipay') > -1) {
        // alipay
        aliPay()
      }else{
        // console.log("假装是微信支付")
        // wxPay(getLocalToken())
        showFailToast('请使用微信或支付宝支付！')

      }
    });

    const auth = () => {
      // 判断是否有token
      let token = getLocalToken();
      if (token) {
        wxPay();
      } else {
        authBase()
      }
    };

    const aliPay = () => {
      let payParams = {deviceNo, count, name}
      if (drawId) {
        payParams = { ...payParams, drawId };
      }
      request({
        url: '/adapay/pay',
        method:"post",
        params: payParams
      }).then((res) => {
        console.log("支付宝支付返回值",res)
        if (res.success) {
          window.location.replace(res.result);
        } else {
          showFailToast(res.error)
        }
      })
    };

    const wxPay = () => {
      let payParams = {deviceNo, count, name}
      if (drawId) {
        payParams = { ...payParams, drawId };
      }
      request({
        url: '/adapay/pay',
        method:"post",
        params: payParams
      }).then((res) => {
        console.log("微信支付返回值",res)
        // return;
        if (res.success) {
          let payString = res.result
          // let payString = "{\"timeStamp\":\"1710749888\",\"package\":\"prepay_id=wx1816180797039338d67609db20148c0000\",\"paySign\":\"c9u4F7IW2Js+/ZZwpysHyt06bkJi8f/AexKO529Bj+9HYNpCxPHn+vcNTNpO3i2SEAry89hFgu4ISNfDeWt9A2F3uox3MGRlUz1fc1Jr8kwZ36Y7t455srUmXh2UR0HluQI9mbOI8szC6txMZI0cFFwIwaydG6yFK1+BTsPVvCWd9X0SSSV0mxtEvHZTg1I/mlWzvCpX5mQLRBKOBI0HyBMOt0Zfz/jfIEUG1mrfdf7hv2sJ0l700/yKh2+J2MGqydu4eNnGroGEmICJTO99TNmvOHqjfteHBQo99cZdTYVHIeXITRVVAypebLjxivdSC/uCyYxHfTs9oQBrC9AgaQ==\",\"appId\":\"wxee0908b04ea053c0\",\"signType\":\"RSA\",\"nonceStr\":\"352jcuwN0oiHMgS4JBmfBAZ26iPwG1aZ\"}";
          payJson = JSON.parse(payString)
          if (typeof WeixinJSBridge == "undefined"){
            if( document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            }else if (document.attachEvent){
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
          }else{
            onBridgeReady();
          }
        } else {
          showFailToast(res.error)
        }
      })



    };
    const onBridgeReady = () => {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', payJson,
        function(res){
        if(res.err_msg == "get_brand_wcpay_request:ok" ){
          // 使用以上方式判断前端返回,微信团队郑重提示：
          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        }
      });
    };


    return {

    }
  }
}
</script>

<style>

</style>
