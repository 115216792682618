<template>
  <div class="container">
    <template v-if="loading">
      <div class="init-container">
        <van-skeleton title :row="3" />
        <van-loading vertical size="24px">加载中</van-loading>
      </div>
    </template>

    <template v-else>
      <div class="content-container">
        <!-- 商品详情 -->
        <div class="good-item">
          <!-- 商品图片 -->
          <div class="good-img">
            <van-image width="90%" :src="product.imageUrl" />
          </div>
          <!-- 商品详情 -->
          <div class="good-info">
            <van-cell-group>
              <van-cell>
                <template #title>
                  <div class="van-multi-ellipsis--l2 good-name" v-html="product.productName"></div>
                </template>
                <template #label>
                  <div class="van-ellipsis price-box">
                    &yen;
                    <span class="good-price">{{ amountFormat(product.price) }}/{{ product.quantity }}{{ product.unit
                      }}</span>
                  </div>
                </template>
              </van-cell>
              <van-cell center>
                <template #title>
                  <span class="label">购买数量</span>
                </template>
                <template #value>
                  <span class="label">1个</span>
                  <!-- <van-stepper v-model="num" min="1" integer input-width="50px" button-size="32px" /> -->
                </template>
              </van-cell>
              <van-cell center>
                <template #title>
                  <span class="count-text">合计</span>
                  <span class="van-ellipsis price-box">
                    &yen;
                    <span class="good-price">{{ countPrice }}</span>
                  </span>
                </template>
                <template #value>
                  <van-button round block class="good-buy-num" @click="sendPay">立即支付</van-button>
                </template>
              </van-cell>
            </van-cell-group>
          </div>
        </div>
      </div>

      <!-- 广告 -->
      <!--      <div class="ad-container">-->
      <!--        <div id="goods-ad"></div>-->
      <!--      </div>-->
    </template>
  </div>
</template>

<script>
import { Button, Loading, Image as VanImage, Field, Stepper, Skeleton, Cell, CellGroup, Col, Row } from 'vant';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { amountFormat } from '@/utils/util';
import { request } from '@/utils/request';
import { getUrlParam } from '@/utils/auth';
import buyIcon from '@/assets/buy_icon.png';
import router from "@/router";

export default {
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Loading.name]: Loading,
    [VanImage.name]: VanImage,
    [Stepper.name]: Stepper,
    [Skeleton.name]: Skeleton,
    [Row.name]: Row,
    [Col.name]: Col,
    [CellGroup.name]: CellGroup,
  },
  setup() {
    // 接受请求参数
    const route = useRoute();
    const { params: { productId } } = route;
    const loading = ref(true);
    const num = ref(1);
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');
    const drawId = getUrlParam('drawId');

    const product = ref({
      productId: productId,
      productName: '',
      price: 10,
      quantity: 0,
      imageUrl: '',
      unit: '',
    });

    onMounted(() => {
      // 加载产品数据
      onLoadProduct();
    });

    // 合计价格
    const countPrice = computed(() => (num.value * product.value.price / 100).toFixed(2));

    const onLoadImg = () => {
      loading.value = true;
      request({
        url: `h5/getPcDraw`,
        method: 'get',
        params: { deviceNo, productId, id: drawId },
      }).then((res) => {
        console.log(res);
        if (res.success === true) {
          product.value.imageUrl = res.result.combineImg;
          // 加载状态结束
          loading.value = false;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const onLoadProduct = () => {
      loading.value = true;
      request({
        url: `/h5/device/getProByDevNoAndProId`,
        method: 'get',
        params: { deviceNo, productId },
      }).then((res) => {
        if (res.success === true) {
          // res.result.
          product.value = { ...product.value, ...res.result };
          // 加载自定义图片
          onLoadImg();
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    // 发起支付
    const sendPay = () => {
      const query = {
        name: product.value.name,
        deviceNo,
        drawId,
        count: num.value,
        timeStamp: Math.floor(Date.now() / 1000),
      };
      router.push({ name: 'pay', query })
    }

    return {
      num,
      sendPay,
      product,
      loading,
      amountFormat,
      countPrice,
      buyIcon,
    }
  }
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F7F8FA;
  .init-container {
    background-color: #F7F8FA;
    padding: 20px 0;
  }
  .ad-container {
    width: 100%;
    height: 80px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 1px solid red;
  }
  .content-container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .good-item {
      width: 100%;
      height: 100%;
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      .good-img {
        flex: 1;
        text-align: center;
        padding: 1rem 0;
        overflow: hidden;
        width: 100%;
        position: relative;
        z-index: 0;
        margin-bottom: 1.2rem;
      }
      .good-info {
        background-color: #fff;
        overflow: hidden;
      }
      .good-name {
        color: #000;
        font-size: 1rem;
        font-weight: 600;
      }
      .price-box {
        color: #F45639;
        font-size: 1rem;
        font-weight: 600;
        .good-price {
          font-size: 1.8rem;
          display: inline-block;
          line-height: 2rem;
        }
      }
      .label {
        font-size: 1.1rem;
        color: #000;
      }
      .count-text {
        font-size: 1rem;
        font-weight: 600;
      }
      .good-buy-num {
        font-size: 1.2rem;
        color: #000;
        font-weight: 600;
        background: linear-gradient(to bottom, #e8f8f6, #faeafa);
        border: none;
      }
    }
  }
}
</style>
