<template>
  <div class="container">
    <div class="content">
      <div class="head-title">
        <span class="van-ellipsis device-no">设备编号：{{ deviceNo }}</span>
        <span class="van-ellipsis" v-if="contactNumber">
          联系电话：<a class="phone" :href="`tel:${contactNumber}`">{{ contactNumber }}</a>
        </span>
      </div>

      <!-- 轮播图 -->
      <div v-if="brands.length !== 0" class="brand-container">
        <van-swipe :autoplay="3000" lazy-render style="width: 100%;height: 100%;">
          <van-swipe-item v-for="brand in brands" :key="brand">
            <img :src="brand" width="100%" alt=""/>
          </van-swipe-item>
        </van-swipe>
      </div>

      <!-- 产品列表 -->
      <van-list v-model:loading="loading" :finished="finished" finished-text="我是有底线的" class="content-container">
        <van-row gutter="10">
          <template v-if="productList.length !== 0">
            <van-col v-for="product in productList" :key="product.productId" span="12" >
              <div class="good-item">
                <router-link :to="{ name: 'coinsDetail', params: { productId: product.productId }, query: { deviceNo } }">
                  <div class="good-img">
                    <van-image class="good-img-item" width="100%" :height="imageHeight" :src="product.imageUrl" />
                  </div>
                  <div class="van-multi-ellipsis--l2 good-name" v-html="product.productName"></div>
                  <div class="van-ellipsis good-price-box">
                    &yen;
                    <span class="good-price">
                      {{ amountFormat(product.price) }}元
                      <span style="font-size: 0.75rem">/{{ product.unit }}</span>
                    </span>
                  </div>
                </router-link>
              </div>
            </van-col>
          </template>
          <template v-if="productList.length === 0">
            <van-col :span="24">
              <van-empty description="没有产品" />
            </van-col>
          </template>
        </van-row>
      </van-list>
    </div>
    <van-tabbar route>
      <van-tabbar-item replace to="/coins" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="manager-o" replace :to="{ name: 'my', query: { deviceNo } }">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Col, Empty, List, Image as VanImage, Row, Swipe, SwipeItem, Cell, Tabbar, TabbarItem } from 'vant';
import { nextTick, onMounted, ref } from 'vue';
import { request } from '@/utils/request';
import { getUrlParam } from '@/utils/auth';
import { amountFormat } from '@/utils/util';
import router from "@/router";

export default {
  components: {
    [Cell.name]: Cell,
    [Col.name]: Col,
    [Empty.name]: Empty,
    [List .name]: List,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  setup() {
    const productList = ref([]);
    const brands = ref([]);
    const finished = ref(false);
    const loading = ref(true);
    const contactNumber = ref('');
    const imageHeight = ref('0px');

    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');

    onMounted(() => {
      // 加载商品列表
      onLoad();
      // 加载设备信息
      getBizInfo();
    });

    const onLoad = () => {
      request({
        url: '/h5/device/getAllProduct',
        method: 'get',
        params: { deviceNo },
      }).then((res) => {
        if (res.success === true) {
          productList.value = res.result;
          // 数据全部加载完成
          finished.value = true;
          // 加载状态结束
          loading.value = false;
          nextTick(() => {
            if (document.getElementsByClassName('good-img-item')[0]) {
              imageHeight.value = document.getElementsByClassName('good-img-item')[0].offsetWidth;
            }
          });
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        // showFailToast('请求出错，请稍后再试！');
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const getBizInfo = () => {
      request({
        url: '/h5/getBizInfo',
        method: 'get',
        params: { deviceNo },
      }).then((res) => {
        if (res.success === true) {
          contactNumber.value = res.result?.serviceTel || '';
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        // showFailToast('请求出错，请稍后再试！');
        router.push({ name: 'error', query: { error: message }})
      });
    };

    return {
      brands,
      productList,
      loading,
      finished,
      deviceNo,
      contactNumber,
      imageHeight,
      amountFormat,
    };
  },
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F7F8FA;
  .content {
    padding: 0 0.7rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
  .head-title {
    display: flex;
    padding: 0.7rem .5rem;
    background-color: #fff;
    margin-bottom: 1rem;
    border-radius: 0.7rem;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    color: #0FC7C1;
    font-size: 0.85rem;
    font-weight: 600;
    .device-no {
      margin-right: 10px;
    }
    .phone {
      color: #000;
    }
  }
  .brand-container {
    margin-bottom: 10px;
    width: 100%;
    height: 260px;
  }
  .ad-container {
    width: 100%;
    height: 80px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 1px solid red;
  }
  .content-container {
    width: 100%;
    flex: 1;
    overflow-y: auto;
  }
  .good-item {
    margin-bottom: 1.3rem;
    font-size: .8rem;
    background-color: #fff;
    border-radius: 1rem;
    overflow: hidden;
    .good-img {
      padding: 0.2rem;
      overflow: hidden;
      width: 100%;
      position: relative;
      z-index: 0;
      .good-stock {
        font-size: 10px;
        position: absolute;
        right: 0;
        top: 0;
        background-color: #5755B3;
        color: #fff;
        padding: 2px;
        border-bottom-left-radius: 5px;
      }
      .no-stock {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, .5);
      }
    }
    .good-name {
      color: #333;
      height: 3.5rem;
      font-size: 1.1rem;
      padding: 0.7rem 1rem;
    }
    .good-price-box {
      color: #F45639;
      padding: 0.7rem 1rem;
    }
    .good-unit {
      color: darkgray;
      font-size: 1rem;
    }
    .good-price {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
}
</style>
