<template>
  <div class="container">
    <div class="content">
      <!-- 产品列表 -->
      <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="我是有底线的"
          class="content-container"
          @load="onLoad"
      >
        <template v-if="orderList.length !== 0">
          <van-space direction="vertical" fill>
            <div v-for="order in orderList" :key="order.id" class="order-box">
              <div class="order-title">订单编号：<b>{{ order.orderNo }}</b></div>
              <van-divider style="margin: 0;color: #000;" />
              <div class="order-content">
                <van-space direction="vertical" fill>
                  <div>
                    <span class="label">商品名称：</span>
                    <span class="value">{{ order.productName}}</span>
                  </div>
                  <div>
                    <span class="label">设备编号：</span>
                    <span class="value">{{ order.deviceNo }}</span>
                  </div>
                  <div>
                    <span class="label">出货状态：</span>
                    <span class="value">
                      <van-tag v-if="order.outStatus === 0" type="danger">出货失败</van-tag>
                      <van-tag v-if="order.outStatus === 1" type="success">出货成功</van-tag>
                      <van-tag v-if="order.outStatus === 2" type="warning">待出货</van-tag>
                    </span>
                  </div>
                  <div v-if="order.outStatus === 0">
                    <span class="label">失败原因: </span>
                    <span class="value">{{ order.outMsg }}</span>
                  </div>
                  <div>
                    <span class="label">交易单号：</span>
                    <span class="value">{{ order.transactionId }}</span>
                  </div>
                  <div>
                    <span class="label">下单时间：</span>
                    <span class="value">{{ formatTime(order.createdAt) }}</span>
                  </div>
                </van-space>

              </div>
              <van-divider style="margin: 0" />
              <div class="order-footer">
                <van-cell title="商品总价">
                  <template #value>
                    <span class="order-total-price">
                      &yen; {{ amountFormat(order.price) }} x {{ order.amount }} = &yen; {{ amountFormat(order.totalFee)}}
                    </span>
                  </template>
                </van-cell>
              </div>
            </div>
          </van-space>
        </template>
        <template v-if="orderList.length === 0">
          <van-empty description="暂无订单" />
        </template>
      </van-list>
    </div>
  </div>
</template>

<script>
import { Button, Col, Empty, List, Image as VanImage, Row, Space, Cell, Divider, Tag } from 'vant';
import { onMounted, ref } from 'vue';
import { request } from '@/utils/request';
import { getUrlParam } from '@/utils/auth';
import { amountFormat } from '@/utils/util';
import router from "@/router";

export default {
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [Col.name]: Col,
    [Empty.name]: Empty,
    [List .name]: List,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Space.name]: Space,
    [Divider.name]: Divider,
    [Tag.name]: Tag,
  },
  setup() {
    const orderList = ref([]);
    // 接受请求参数
    const finished = ref(false);
    const loading = ref(true);
    const pageNo = ref(0);
    const pageSize = 10;
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');

    onMounted(() => {
      // 加载商品列表
      onLoad();
    });

    const onLoad = () => {
      pageNo.value++;
      request({
        url: '/h5/pagingCoinsPayOrder',
        method: 'get',
        params: { deviceNo, pageNo: pageNo.value, pageSize },
      }).then((res) => {
        if (res.success === true) {
          orderList.value.push(...res.result.data);
          // 数据全部加载完成
          finished.value = res.result.total <= orderList.value.length;
          // 加载状态结束
          loading.value = false;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const formatTime = (timestamp) => {
      // 创建一个Date对象，传入时间戳作为参数
      const date = new Date(timestamp);

      // 使用Date对象的方法获取年月日时分秒
      const year = date.getFullYear(); // 年
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月，注意月份需要加1
      const day = ('0' + date.getDate()).slice(-2); // 日
      const hours = ('0' + date.getHours()).slice(-2); // 时
      const minutes = ('0' + date.getMinutes()).slice(-2); // 分

      // 构造格式化后的时间字符串
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    return {
      orderList,
      loading,
      finished,
      deviceNo,
      pageNo,
      amountFormat,
      onLoad,
      formatTime,
    };
  },
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F7F8FA;
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
  .content-container {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    .order-box {
      font-size: 0.85rem;
      background: white;
      margin-top: var(--van-dialog-radius);
      .order-title {
        padding: 0.7rem;
      }
      .order-content {
        padding: 0.7rem;
        .label {
          color: darkgrey;
        }
        .value {
          color: #000;
          font-weight: 700;
        }
      }
      .order-footer {
        .order-total-price {
          color: red;
          font-weight: 600;
        }
        :deep(.van-cell) {
          padding: 0.5rem 0.7rem;
        }
        :deep(.van-cell__title) {
          color: darkgrey;
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>
