<template>
  <div class="container">
    <div class="content">
      <van-checkbox-group v-model="selectId" direction="horizontal" style="overflow-y:auto;">
        <!-- 产品列表 -->
        <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="我是有底线的"
            class="content-container"
            @load="onLoad"
        >
          <van-row gutter="10">
            <template v-if="productList.length !== 0">
              <van-col v-for="(product, index) in productList" :key="product.id" span="12" >
                <div class="good-item" @click="toggle(index)">
                  <van-checkbox :name="product.id" :ref="el => checkboxRefs[index] = el"
                                @click.stop></van-checkbox>
                    <div class="good-img">
                      <van-image width="100%" :src="product.combineImg" />
                    </div>
                    <div class="good-create-at">
                      <div class="good-created-label">绘制时间：</div>
                      <div>{{ formatTime(product.createTime) }}</div>
                    </div>
                </div>
              </van-col>
            </template>
            <template v-if="productList.length === 0">
              <van-col :span="24">
                <van-empty description="暂无内容" />
              </van-col>
            </template>
          </van-row>
        </van-list>
      </van-checkbox-group>
    </div>

    <!-- 底部按钮 -->
    <van-grid clickable :column-num="2" class="customer-btn">
      <van-grid-item icon="delete-o" text="删除" @click="destroy" />
      <van-grid-item icon="revoke" text="退出管理" :to="{ name: 'my', query: { deviceNo } }" />
    </van-grid>

  </div>
</template>

<script>
import {
  Button,
  Col,
  Empty,
  List,
  Image as VanImage,
  Row,
  Grid,
  GridItem,
  Cell,
  Checkbox,
  CheckboxGroup,
  showConfirmDialog,
  showFailToast,
  showSuccessToast
} from 'vant';
import { onMounted, ref, onBeforeUpdate } from 'vue';
import { request } from '@/utils/request';
import { getUrlParam } from '@/utils/auth';
import { amountFormat } from '@/utils/util';
import router from "@/router";

export default {
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [Col.name]: Col,
    [Empty.name]: Empty,
    [List .name]: List,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  setup() {
    const productList = ref([]);
    // 接受请求参数
    const selectId = ref([]);
    const checkboxRefs = ref([]);
    const typeLogo = ref('');
    const finished = ref(false);
    const loading = ref(true);
    const pageNo = ref(0);
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');

    onMounted(async () => {
      // 加载设备类型
      await onDeviceInfo();
      // 加载商品列表
      await onLoad();
    });

    const onDeviceInfo = async () => {
      await request({
        url: '/h5/device/getDeviceInfo',
        method: 'get',
        params: { deviceNo }
      }).then((res) => {
        if (res.success === true && res.result) {
          typeLogo.value = res.result.typeLogo;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    onBeforeUpdate(() => {
      checkboxRefs.value = [];
    });

    const onLoad = () => {
      pageNo.value++;
      request({
        url: '/h5/pagingPcDraw',
        method: 'get',
        params: { deviceNo, pageNo: pageNo.value, typeLogo: typeLogo.value },
      }).then((res) => {
        if (res.success === true) {
          productList.value.push(...res.result.data);
          // 数据全部加载完成
          finished.value = res.result.total <= productList.value.length;
          // 加载状态结束
          loading.value = false;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        showFailToast('请求出错，请稍后再试！');
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const formatTime = (timestamp) => {
      // 创建一个Date对象，传入时间戳作为参数
      const date = new Date(timestamp);

      // 使用Date对象的方法获取年月日时分秒
      const year = date.getFullYear(); // 年
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月，注意月份需要加1
      const day = ('0' + date.getDate()).slice(-2); // 日
      const hours = ('0' + date.getHours()).slice(-2); // 时
      const minutes = ('0' + date.getMinutes()).slice(-2); // 分

      // 构造格式化后的时间字符串
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const toggle = (index) => {
      checkboxRefs.value[index].toggle();
    };

    const destroy = () => {
      if (selectId.value.length === 0) {
        showFailToast('请选择素材！');
        return false;
      }
      showConfirmDialog({
        title: '提示',
        message: '确定删除选中的素材吗？',
      }).then(() => {
        const formData = new FormData();
        formData.append('ids', selectId.value);
        request({
          url: '/h5/deletesPcDraws',
          method: 'post',
          data: formData,
        }).then((res) => {
          if (res.success === true) {
            showSuccessToast('删除成功！');
            pageNo.value = 0;
            productList.value = [];
            selectId.value = [];
            onLoad();
          }
        }).catch(({ response }) => {
          const { data: { message }} = response;
          showFailToast('请求出错，请稍后再试！');
          router.push({ name: 'error', query: { error: message }})
        });
      }).catch(() => {
      });
    }

    return {
      selectId,
      productList,
      loading,
      finished,
      deviceNo,
      pageNo,
      checkboxRefs,
      amountFormat,
      toggle,
      destroy,
      onLoad,
      formatTime,
    };
  },
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F7F8FA;
  .content {
    padding: 0 0.7rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
  .head-title {
    display: flex;
    padding: 1rem 1.5rem;
    background-color: #fff;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.7rem;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    color: #000;
    font-size: 1.1rem;
    font-weight: 600;
  }
  .content-container {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
  }
  .good-item {
    position: relative;
    margin-top: 1rem;
    background-color: #fff;
    border-radius: 1rem;
    overflow: hidden;
    padding-bottom: 0.7rem;
    :deep(.van-checkbox) {
      position: absolute;
      left: 8px;
      top: 8px;
    }
    .good-img {
      padding: 0.2rem;
      overflow: hidden;
      width: 100%;
      height: 12rem;
      position: relative;
      z-index: 0;
    }
    .good-create-at {
      color: #000;
      font-size: 0.9rem;
      padding: 0.7rem 1rem;
      .good-created-label {
        margin-bottom: 0.3rem;
      }
    }
  }
  .customer-btn {
    :deep(.van-grid-item__content) {
      padding: var(--van-padding-xs);
      color: var(--van-step-text-color);
    }
  }
}
</style>
