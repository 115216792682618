<template>
  <router-view v-wechat-title="$route.meta.title" />
</template>

<script>
import { onBeforeMount, provide } from "vue";
import wxmeans from "./utils/wxmeans";
import VConsole from "vconsole";

// 腾讯广告全局申明只需运行一次
window.TencentGDT = window.TencentGDT || [];

// H5 SDK接入全局只需运行一次
(function () {
  var doc = document,
      h = doc.getElementsByTagName('head')[0],
      s = doc.createElement('script');
  s.async = true;
  s.src = 'https://qzs.gdtimg.com/union/res/union_sdk/page/h5_sdk/i.js';
  h && h.insertBefore(s, h.firstChild);
})();

export default {
  name: 'App',

  setup() {
    onBeforeMount(() => {
      // if (process.env.VUE_APP_MODE == "development" && location.host != "localhost:8091") {
      //   const vConsole = new VConsole();
      // }
      let ua = userAgent();
    });
    const userAgent = () => {
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf('micromessenger') > -1) {
        // 加载jssdk
        wxmeans();

         // 提供全局变量
         provide('globalEnv', "weixin");
        return 'weixin'
      } else if (ua.indexOf('alipay') > -1) {
        provide('globalEnv', "alipay");
        return 'alipay'
      } else {
        provide('globalEnv', "unKnow");
        // Dialog.alert({
        //   message: '请使用微信或支付宝扫码'
        // }).then((res) => {
        //   router.replace({name:"error"})
        // })
      }
    };

    return {

      // $enV:(window.navigator.userAgent.toLowerCase().indexOf('alipay') > -1)?"alipay":"weixin"

    };
  },
}
</script>

<style>
#app {
  height: 100vh;
}
</style>
