<template>
  <div class="container">
    <div class="head-box">
      <div class="bg-color">
        <van-space :size="20" @click="getUserInfo">
          <van-image round width="5rem" height="5rem" :src="active" />
          <van-space class="user-info" :size="5" direction="vertical">
            <div class="realname" v-html="realname || '未知用户'"></div>
            <template v-if="mobile">
              <div class="mobile">账号：{{ mobile }}</div>
            </template>
            <template v-if="!realname">点击登录</template>
          </van-space>
        </van-space>
      </div>
      <van-cell-group class="order-item" inset>
        <template v-if="realname && active">
          <router-link :to="{ name: 'order', query: { deviceNo } }">
            <van-cell :title-style="{fontSize: '1.1rem'}" title="我的订单" value="全部订单" center is-link />
          </router-link>
        </template>
        <template v-else>
          <van-cell :title-style="{fontSize: '1.1rem'}" title="我的订单" value="全部订单" center is-link @click="getUserInfo" />
        </template>
      </van-cell-group>
    </div>

    <van-cell-group inset border>
      <template v-if="realname && active">
        <router-link :to="{ name: 'coinsMaterialManage', query: { deviceNo } }">
          <van-cell icon="photo-o" :title-style="{fontSize: '1.2rem'}" title="素材库" center is-link />
        </router-link>
      </template>
      <template v-else>
        <van-cell icon="photo-o" :title-style="{fontSize: '1.2rem'}" title="素材库" center is-link @click="getUserInfo" />
      </template>
      <van-cell
          v-if="contactNumber"
          icon="service-o"
          :title-style="{fontSize: '1.2rem'}"
          title="联系客服"
          center
          is-link
          @click="customerServiceShow = true"
      />
    </van-cell-group>

    <van-tabbar route>
      <van-tabbar-item replace :to="{ name: 'coins', query: { deviceNo } }" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="manager-o" replace :to="{ name: 'my', query: { deviceNo } }">我的</van-tabbar-item>
    </van-tabbar>

    <van-dialog v-model:show="customerServiceShow" title="联系客服" :show-confirm-button="false" closeOnClickOverlay>
      <div class="customer-service-box">
        <span>客服电话:</span>
        <a class="phone" :href="`tel:${contactNumber}`">{{ contactNumber }}</a>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Image as VanImage, Tabbar, TabbarItem, Cell, CellGroup, Col, Row, Space, Dialog, showDialog } from 'vant';
import { authUserInfo, getUrlParam } from "@/utils/auth";
import { onMounted, ref } from "vue";
import { getLocalToken } from "@/utils/token";
import { request } from "@/utils/request";
import router from "@/router";

export default {
  components: {
    [VanImage.name]: VanImage,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Col.name]: Col,
    [Row.name]: Row,
    [Space.name]: Space,
    [Dialog.name]: Dialog,
  },
  setup() {
    // 接受请求参数
    const deviceNo = getUrlParam('deviceNo');
    // 头像
    const active = ref('');
    // 姓名
    const realname = ref('');
    // 电话
    const mobile = ref('');
    const customerServiceShow = ref(false);
    const contactNumber = ref('');

    onMounted(() => {
      // 获取设备信息
      getBizInfo();
      // 获取用户信息
      getAuthUserInfo();
    });

    // 获取用户信息
    const getAuthUserInfo = () => {
      request({
        url: "/h5/getUserInfo",
      }).then((res) => {
        if (res.success && res.result) {
          console.log(res);
          const { name, headImgUrl } = res.result;
          realname.value = name;
          active.value = headImgUrl;
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const getBizInfo = () => {
      request({
        url: '/h5/getBizInfo',
        method: 'get',
        params: { deviceNo },
      }).then((res) => {
        if (res.success === true) {
          contactNumber.value = res.result?.serviceTel || '';
        }
      }).catch(({ response }) => {
        const { data: { message }} = response;
        // showFailToast('请求出错，请稍后再试！');
        router.push({ name: 'error', query: { error: message }})
      });
    };

    const getUserInfo = async () => {
      // 用户有头像或者有姓名时跳过
      if (active.value && realname.value) {
        return;
      }
      // 授权授权用户信息
      await authUserInfo();
      // 获取当前登录用户数据
      await getAuthUserInfo();
    }

    return {
      deviceNo,
      active,
      realname,
      mobile,
      contactNumber,
      customerServiceShow,
      getUserInfo,
    }
  }
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F7F8FA;
  .head-box {
    width: 100%;
    height: 13rem;
    margin-bottom: 1rem;
    position: relative;
    .bg-color {
      width: 100%;
      height: 90%;
      background: linear-gradient(to bottom, #0393F6, #D6F5E5);
      padding-left: var(--van-cell-group-title-line-height);
      padding-right: var(--van-cell-group-title-line-height);
      padding-top: 3rem;
      .user-info {
        color: #fff;
        .realname {
          font-size: 1.2rem;
        }
        .mobile {
          font-size: 1rem;
        }
      }
    }
    .order-item {
      width: calc(100vw - 32px);
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .van-cell {
    height: 4rem;
  }
}
.customer-service-box {
  font-size: 1.2rem;
  padding: 1rem;
  text-align: center;
  .phone {
    margin-left: 0.7rem;
  }
}
</style>
