import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import vueWechatTitle from 'vue-wechat-title'
// 引入全局样式表
import './assets/css/global.css'
import 'vant/lib/index.css';

const app = createApp(App);
app.use(router);
app.use(vueWechatTitle);
app.mount('#app');
