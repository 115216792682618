<template>
  <van-action-sheet v-model:show="show" @close="close">
    <div class="customer-text-container">
      <van-nav-bar title="文字" @clickLeft="close" @clickRight="submit">
        <template #left>
          <van-icon name="cross" size="22" />
        </template>
        <template #right>
          <van-icon name="success" size="22" />
        </template>
      </van-nav-bar>

      <van-field
          v-model="text"
          size="large"
          label="文本"
          placeholder="输入要展示的文字"
          label-align="top"
          autocomplete="off"
          @update:model-value="textChange"
      />
      <div class="text-bold-box">
        <div class="text-bold-text">加粗</div>
        <van-button :icon="fontBoldPng" size="normal" :type="bold ? 'warning' : 'default'" @click="bold = !bold" />
      </div>
      <div class="text-bold-box">
        <div class="text-bold-text">字体</div>
        <van-button
            v-for="(item, index) in fontFamilyOption"
            :key="index"
            size="small"
            :type="fontFamily === item.value ? 'warning' : 'default'"
            style="margin-right: 10px;margin-top: 10px"
            @click="fontFamily = item.value"
        >
          {{ item.text }}
        </van-button>
      </div>
      <div class="text-bold-box" style="height: 4rem;">
        <span :style="{ fontFamily: fontFamily, fontWeight: bold ? 'bold' : 'normal', fontSize: '2rem' }">
          {{ text ? text: '字体预览' }}
        </span>
      </div>
    </div>
  </van-action-sheet>
</template>

<script>
import {
  ActionSheet,
  Button,
  Icon,
  Field,
  CellGroup,
  NavBar,
  Picker,
  Popup,
  Cell,
  Tag,
  showLoadingToast,
  showFailToast
} from 'vant';
import { watch, ref } from "vue";
import fontBoldPng from '@/assets/font-bold.png';

export default {
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [ActionSheet.name]: ActionSheet,
    [Field.name]: Field,
    [NavBar.name]: NavBar,
    [CellGroup.name]: CellGroup,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
  },
  props: {
    customerTextShow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'change'],
  setup(props, { emit }) {
    watch(
        () => props.customerTextShow,
        (val) => {
          if (val) {
            text.value = '';
            bold.value = false;
            fontFamily.value = '宋体';
          }
          show.value = val;
        }
    );

    const show = ref(false);
    const text = ref('');
    const bold = ref(false);
    const fontFamily = ref('宋体');
    const showPicker = ref(false);
    const fontFamilyOption = [
      { text: '默认字体', value: '宋体' },
      { text: '字体1', value: '迷你简幼线' },
      { text: '字体2', value: '迷你简毡笔黑' },
      { text: '字体3', value: '迷你简竹节' },
      { text: '字体4', value: '迷你简北魏楷书' },
      { text: '字体5', value: '迷你简硬笔楷书' },
      { text: '字体6', value: '迷你简祥隶体' },
    ];
    watch(
        fontFamily,
        (val) => {
          if (val === '宋体') {
            return;
          }
          const loadingToast = showLoadingToast({
            message: '加载中....',
            duration: 0,
            forbidClick: true,
          });
          const font = new FontFace(val, `url(${require(`@/assets/fonts/${val}.TTF`)})`);
          font.load().then(async () => {
            document.fonts.add(font);
            loadingToast.close();
          });
        }
    );
    const close = () => {
      emit('close');
    };

    const removeEmojis = (str) => {
      // 定义一个正则表达式来匹配 Emoji
      const emojiRegex = /[\u{1F601}-\u{1F64F}\u{2702}-\u{27B0}\u{1F680}-\u{1F6C0}\u{1F170}-\u{1F251}\u{1F600}-\u{1F636}\u{1F681}-\u{1F6C5}\u{1F30D}-\u{1F567}]/gu;

      return {
        match: str.match(emojiRegex),
        text: str.replace(emojiRegex, ''),
      };
    }

    const textChange = (val) => {
      const res = removeEmojis(val)
      if (res.match) {
        showFailToast('不支持输入表情哦');
        setTimeout(() => text.value = res.text, 100)
      }
    };

    const submit = () => {
      emit('change', { text: text.value, bold: bold.value, fontFamily: fontFamily.value})
    };

    return {
      show,
      text,
      bold,
      fontBoldPng,
      fontFamily,
      showPicker,
      fontFamilyOption,

      textChange,
      close,
      submit,
    }
  }
}
</script>

<style scoped lang="less">
.customer-text-container {
  :deep(.van-field__label) {
    font-size: 1rem;
    font-weight: 600;
  }
  :deep(.van-field__control) {
    font-size: 1rem;
    padding: 0.8rem;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .text-bold-box {
    padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
    .text-bold-text {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: var(--van-padding-base);
    }
  }
}
</style>
