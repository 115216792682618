<template>
  <div class="error">
    <div class="error_box">
      <div class="title">出错了!</div>
      <img src="../../assets/socket.png" alt="">
      <div class="text">
        {{ message || '发生错误啦!' }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { getUrlParam } from '@/utils/auth';

export default {
  setup() {
    const message = ref('');

    message.value = getUrlParam('error');

    return {
      message,
    };
  }
}
</script>

<style lang="less" scoped>
.error{
  background: #F8F8F8;
  height: 100%;

  .error_box{
    background-color: orange;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 2rem;
    }
    
    img{
      width: 20%;
    }

    .text {
      color: antiquewhite;
      padding: 10px 1.5rem;
      line-height: 1.7rem;
      font-size: 1.2rem;
    }

  }
}

</style>