<template>
  <van-action-sheet v-model:show="show" @close="closePhoto">
    <div class="photo-container">
      <van-nav-bar :title="photoTitle" @clickLeft="closePhoto" @clickRight="closePhoto">
        <template #left>
          <van-icon name="cross" size="22" />
        </template>
        <template #right>
          <van-icon name="success" size="22" />
        </template>
      </van-nav-bar>

      <van-tabs v-model:active="photoActive" shrink class="photo-box">
        <van-tab title="动物世界">
          <van-grid square :border="false">
            <van-grid-item v-for="(value, index) in photos.animal" :key="index" @click="submitPhoto(value)">
              <van-image :src="value" width="100%" />
            </van-grid-item>
          </van-grid>
        </van-tab>
        <van-tab title="2024热门">
          <van-grid square :border="false">
            <van-grid-item v-for="(value, index) in photos.year" :key="index" @click="submitPhoto(value)">
              <van-image :src="value" width="100%" />
            </van-grid-item>
          </van-grid>
        </van-tab>
      </van-tabs>
    </div>
  </van-action-sheet>
</template>

<script>
import { ref, toRefs, watch } from "vue";
import { ActionSheet, Grid, GridItem, Icon, Image as VanImage, NavBar, Tab, Tabs } from 'vant';

export default {
  components: {
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [ActionSheet.name]: ActionSheet,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  props: {
    photoShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    }
  },
  emits: ['close', 'change'],
  setup(props, { emit }) {
    watch(
        () => props.photoShow,
        (val) => {
          if (val) {
            photoActive.value = 0;
          }
          show.value = val;
        }
    );
    const { title: photoTitle } = toRefs(props);
    const photoActive = ref(0);
    const show = ref(false);
    const photos = ref({
      // 动物世界
      animal: [
        require('@/assets/icons/1.png'),
        require('@/assets/icons/2.png'),
        require('@/assets/icons/3.png'),
        require('@/assets/icons/4.png'),
      ],
      // 热门
      year: [
        require('@/assets/icons/1.png'),
        require('@/assets/icons/2.png'),
        require('@/assets/icons/3.png'),
        require('@/assets/icons/4.png'),
      ],
    });

    const submitPhoto = (photo) => {
      emit('change', photo);
    };

    const closePhoto = () => {
      emit('close', false);
    };

    return {
      show,
      photoTitle,
      photos,
      photoActive,
      submitPhoto,
      closePhoto,
    };
  }
}
</script>

<style scoped lang="less">
.photo-container {
  .photo-box {
    :deep(.van-tabs__content) {
      height: 300px;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
}
</style>
